import React from "react"
import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { TestimonialSection } from 'components/sections'
import { SignupButton, Row, Typography,  Container, Section, SectionTitle, FeatureGrid, Feature, FeatureRows } from "components/ui"
import Image from "components/image"
import { ValuePropositions } from 'components/sections'

const ContentPage = () => (
  <Layout>
    <SEO refKey="incremental-backup" />

    <Section color="alt">
      <Container>
        <Row>
          <div className="col-md-12 text-center">
            <SectionTitle
              tag="h1"
              align="center"
              title={(<><strong>Incremental backups.</strong><br/> We made it simple.</>)}
              subTitle={(<>Back up what matters, run backups more frequently, save on storage cost, restore to a point-in-time.</>)}
            />
            <SignupButton className="mt-12" text="Create your first backup" urlAtrr={{ sb_source: "website", sb_term: "increment" }} />

            <FeatureGrid cols="3" className="mt-16">
              <Feature
                icon={<i className="far fa-rabbit-fast" />}
                title="Quicker backup runs"
                content="Run backup faster (way faster actually) and open up to more frequent backup strategies."
                boxed
              />
              <Feature
                icon={<i className="far fa-cloud" />}
                title="Less storage space"
                content="Only back up changed files, means saving up a lot of storage space. Over time this can quickly translate into big storage cost savings."
                boxed
              />
              <Feature
                icon={<i className="far fa-memory" />}
                title="Less server resources"
                content="Less files to back up, means faster backups but also less server resources needed."
                boxed
              />
            </FeatureGrid>
          </div>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <SectionTitle
            title={(<>Efficient backups made simple</>)}
            tag="h2"
            align="center"
          />
          <div className="col-md-6">
              <Typography tag="h3">Optimized resource usage</Typography>
              <Typography tag="p" className="mb-2">
                Large backups can take time and this time will grow together with your backup size.<br/>
                With incremental backups, we just back up the data that changed since the last backup run, keeping resources optimized and under control.<br/><br/>
              </Typography>
          </div>
          <div className="col-md-6">
              <Typography tag="h3">It's complex, we made it simple</Typography>
              <Typography tag="p" className="mb-2">
                Configuring incremental backups is tedious and when you have to restore them, it becomes a nightmare.<br/>
                With SimpleBackups, simply tick the "incremental backup" box, define your retention and you're done.
            </Typography>
          </div>

        </Row>

        <FeatureRows items={
          [
            {
              title: "Faster restore time",
              description: (
                <>
                  <p>
                    Restoring incremental backups is hectic.<br/>
                    We made this super simple, one command and your backup is fully reconstructed.<br/>
                    We take care of bundling increments on top of level-0 backups without you having to think about it.
                  </p>
                </>
              ),
              image: (<Image filename="simplebackups-incremental-restore.png" alt="SimpleBackups restore incremental backup" style={{maxWidth: '484px'}}/>)
        }]}/>

        <Row>
          <div className="colspan-12">
            <Typography tag="h2" className="text-center">
                Why you should use SimpleBackups
            </Typography>
            <ValuePropositions/>
          </div>
        </Row>
      </Container>
    </Section>

    <TestimonialSection/>

  </Layout>
)

export const frontmatter = {
  pageMetaData: {
    refKey: "incremental-backup",
    title: "Incremental backup for your files and databases",
    description: "Incremental Backup captures only the changed files and folders since the last incremental backup. It saves both, time and storage space, and also ensures that your backup data is up to date.",
  }
}

export default ContentPage
